import React from "react"
import Seo from "../components/seo"
import Root from "../components/Root"
import { Box, Typography, Grid, Container } from "@material-ui/core"

const IndexPage = () => (
  <Root>
    <Seo title="Citate din cărți românești, străine pentru toată lumea." />
    <div>
      <Container component="div" maxWidth="sm" style={{ marginTop: 96 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={12}>
            <Box style={{ marginBottom: 32, textAlign: "center" }}>
              <Typography variant="h4">
                “Discover the wisdom of literature – one scan, one quote at a
                time with O mie de semne.”
              </Typography>
            </Box>

            <Box style={{ marginBottom: 32, textAlign: "center" }}>
              <Typography variant="h6" style={{ marginBottom: 16 }}>
                AUTOR
              </Typography>

              <Typography variant="subtitle2">O mie de semne</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  </Root>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
